@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Acme&family=Amiri&family=Anton&family=Arvo:ital,wght@0,400;1,700&family=Baloo+Bhai+2&family=Bebas+Neue&family=BhuTuka+Expanded+One&family=Ceviche+One&family=Cinzel&family=Comforter+Brush&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Gentium+Book+Plus&family=Gentium+Plus&family=Heebo&family=Henny+Penny&family=Ibarra+Real+Nova&family=Inconsolata:wght@300&family=Josefin+Sans:wght@100&family=Kanit:wght@300&family=Kaushan+Script&family=Libre+Baskerville&family=Lobster&family=Lora&family=Oleo+Script+Swash+Caps&family=Open+Sans:wght@500&family=Oswald:wght@300&family=Philosopher&family=Playball&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@700&family=Rubik+Dirt&family=Slabo+27px&family=Source+Serif+Pro:ital,wght@1,600&family=Space+Mono:ital@1&family=Tiro+Kannada&family=Tiro+Tamil&family=Tiro+Telugu&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Abril+Fatface&family=Acme&family=Alumni+Sans+Collegiate+One&family=Amiri&family=Anton&family=Arvo:ital,wght@0,400;1,700&family=Baloo+Bhai+2&family=Bangers&family=Bebas+Neue&family=BhuTuka+Expanded+One&family=Ceviche+One&family=Cinzel&family=Comforter+Brush&family=Crimson+Text&family=EB+Garamond&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Fredericka+the+Great&family=Gentium+Book+Plus&family=Gentium+Plus&family=Heebo&family=Henny+Penny&family=Ibarra+Real+Nova&family=Inconsolata:wght@300&family=Josefin+Sans:wght@100&family=Kanit:wght@300&family=Kaushan+Script&family=Libre+Baskerville&family=Lobster&family=Lora&family=Merriweather:wght@300&family=Montserrat+Subrayada&family=Nanum+Myeongjo&family=Norican&family=Nunito:wght@200&family=Oleo+Script+Swash+Caps&family=Open+Sans:wght@500&family=Oswald:wght@300&family=Philosopher&family=Playball&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@700&family=Rubik+Dirt&family=Rubik+Microbe&family=Rubik+Wet+Paint&family=Slabo+27px&family=Slackey&family=Source+Serif+Pro:ital,wght@1,600&family=Space+Mono:ital@1&family=Tiro+Kannada&family=Tiro+Tamil&family=Tiro+Telugu&family=Varela+Round&display=swap');

/* /////////////////////////////////// section1 ///////////////////////////////// */
.ToursInfo-banner-image {
    height: 86vh;
    background: url("../Images/Untitled_Panorama1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


/* /////////////////////////////////// section2 ///////////////////////////////// */


.ToursInfo-Section2 {
    margin-bottom: 5vh;
}

.ToursInfo-TourName {
    margin-top: 5vh;
}

.ToursInfo-TourName h2 {
    color: #808080;
    font-family: 'Fredericka the Great', cursive;
    font-weight: 900;
}
.ToursInfo-TourName h1 {
    color: #808080;
    font-family: 'Fredericka the Great', cursive;
    font-weight: 900;
}
.ToursInfo-TourName h4 {
    color: #808080;
    font-family: 'Fredericka the Great', cursive;
    font-weight: 900;
}


/* /////////////////////////////////// section3 ///////////////////////////////// */


.ToursInfo-Section3 {
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.ToursInfo-Section3-Itinerary {
    /* border: 1px solid black; */
}

.ToursInfo-swiper {
    /* border: 1px solid red; */
    overflow: hidden;
    height: 300px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.Itinerary-div {
    /* border: 1px solid blueviolet; */
}

.accordion-item {}

.accordion-body {
    text-align: left;
    background-color: #c2c2c240;
}

.accordion-body p {
    padding: 0px;
    margin: 0px;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 16px;
}

.accordion-button {
    margin: 5px;
    width: 99%;
}

/* .swiper-slide img {
    height: 50vh;
    width: 100%;
} */

#ToursInfoSwiper img {
    height: 50vh !important;
    width: 100% !important;
}

/* ////////////////////////////////////////////////  */

.costinclude-image {
    /* border: 1px solid red; */
    height: 100%;
    padding: 10px;
}

.costinclude-image img {
    height: 100%;
    width: 100%;
}

/* ////////////////////////////////////////////////////// section 4 ///////////////////////////////////////////////////////// */



.ToursInfo-Section4 {
    margin-top: 5vh;
}

.ToursInfo-Section4-Data {
    text-align: left;
    /* border: 1px solid red; */
}

ul.list_ok {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 22px;
}

ul.list_ok li {
    position: relative;
    padding-left: 25px;
}

.list_ok li::before {
    font-style: normal;
    font-weight: bold;
    font-family: "icon_set_1";
    font-size: 14px;
    content: "✓";
    color: #e04f67;
    position: absolute;
    left: 0;
    top: 0;
}

.Cost-Includes h4 {
    font-size: 20px;
    font-family: 'Merriweather', serif;
    font-weight: bold;
}
.Cost-offer {
  display: flex;
}



.Cost-Includes ul li {
    color: gray;
    margin-left: 16px;
    font-size: 15px;
}


.Sightseeing h4 {
    font-size: 15px;
    font-family: 'Merriweather', serif;
    font-weight: bold;

}

.Sightseeing ul li {
    color: gray;
    font-size: 15px;
}

.Extra h4 {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 30px;
}

.Extra ul li {
    color: gray;
}

.Note h4 {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 15px;
}

.Note ul li {
    color: gray;
}

.Important h4 {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 15px;
}

.Important ul li {
    color: gray;
    font-size: 15px;
}

.Inquiry{
    margin: 20px;
}

.Side-Images {
    height: 100%;
    width: 100%;
    border: 1px solid rgb(81, 0, 255);
    padding: 5vh;
    position: relative;
    display: contents;
    border: 1px solid red;
}

.Side-Images01 {
    border-radius: 5px;
    height: 40vh;
    transform: rotate(5deg);
    margin-top: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    z-index: -1;
}

.Side-Images01 img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Side-Images02 {
    height: 40vh;
    transform: rotate(-19deg);
    margin-top: 25px;
    box-shadow: 0 0 5px #071feb;
    border-radius: 5px;
}

.Side-Images02 img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Side-Images03 {
    height: 40vh;
    transform: rotate(19deg);
    margin-top: 25px;
    box-shadow: 0 0 5px #071feb;
    border-radius: 5px;
}

.Side-Images03 img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}


/* /////////////////////////////////////////////////////////////////////// */

.Offer {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.startAt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #808080;
    padding-bottom: 10px;
    left: -5%;
    top: 19%;
    box-shadow: 0 0 5px #808080;
    z-index: 1;
    width: 70vh;
    border-radius: 20px;
}


.startAt p {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    color: white;
}

.startAt-Price {
    margin-top: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: gray;
    border-radius: 31vh;
    height: 20vh;
    width: 20vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 50vh;
    position: absolute;
    z-index: 1;
    top: 40%;
    right: -15%;
}

.startAt h5 {
    width: fit-content;
    color: #ffffffe3;
    margin: 0px;
    padding: 0px;
    font-size: 23px;

}
.startAt h6 {
    width: fit-content;
    color: #ffffffe3;
    margin: 0px;
    padding: 0px;
    font-size: 23px;

}

.startAt p {
    /* border: 1px solid gray; */
    width: fit-content;
    color: #ffffffe3;
    margin: 0px;
    padding: 0px;
    font-size: 19px;
}

#outlined-basic{
    width: 100%;
}