/* ================================= Footer ============================ */

.footer-basic {
    padding: 40px 0;
    background-color: #c3c3c3;
    color: #fff;
    margin-top: 65px;
}

.footer-basic ul {
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0;
}

.footer-basic li {
    padding: 0 5px;
}

.footer-basic ul a {
    color: rgba(0, 0, 0, 0.604);
    text-decoration: none;
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    padding: 3px 7px;
}

.footer-basic ul a:hover {
    opacity: 1;
    color: rgba(0, 0, 0, 0.597);
    background: #fff;
    transition: 0.5s ease-in-out;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px #fff;
}

.footer-basic .social {
    text-align: center;
    padding-bottom: 25px;
}

.footer-basic .social>a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 8px;
    color: inherit;
    opacity: 0.75;
    transition: 0.5s ease-in-out;

}

.footer-basic .social>a:hover {
    opacity: 1;
    background: #fff;
    color: var(---primeColor);
    box-shadow: 0 0 5px 5px #fff;
    border: none;
}

.footer-basic .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
}

.copyright a {
    text-align: center;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
}

.list-inline-item a {
    color: black;
}