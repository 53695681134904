@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Abril+Fatface&family=Acme&family=Alumni+Sans+Collegiate+One&family=Amiri&family=Anton&family=Arvo:ital,wght@0,400;1,700&family=Baloo+Bhai+2&family=Bebas+Neue&family=BhuTuka+Expanded+One&family=Ceviche+One&family=Cinzel&family=Comforter+Brush&family=Crimson+Text&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Gentium+Book+Plus&family=Gentium+Plus&family=Heebo&family=Henny+Penny&family=Ibarra+Real+Nova&family=Inconsolata:wght@300&family=Josefin+Sans:wght@100&family=Kanit:wght@300&family=Kaushan+Script&family=Libre+Baskerville&family=Lobster&family=Lora&family=Merriweather:wght@300&family=Nunito:wght@200&family=Oleo+Script+Swash+Caps&family=Open+Sans:wght@500&family=Oswald:wght@300&family=Philosopher&family=Playball&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@700&family=Rubik+Dirt&family=Slabo+27px&family=Source+Serif+Pro:ital,wght@1,600&family=Space+Mono:ital@1&family=Tiro+Kannada&family=Tiro+Tamil&family=Tiro+Telugu&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Abril+Fatface&family=Acme&family=Alumni+Sans+Collegiate+One&family=Amiri&family=Anton&family=Arvo:ital,wght@0,400;1,700&family=Baloo+Bhai+2&family=Bebas+Neue&family=BhuTuka+Expanded+One&family=Ceviche+One&family=Cinzel&family=Comforter+Brush&family=Crimson+Text&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Gentium+Book+Plus&family=Gentium+Plus&family=Heebo&family=Henny+Penny&family=Ibarra+Real+Nova&family=Inconsolata:wght@300&family=Josefin+Sans:wght@100&family=Kanit:wght@300&family=Kaushan+Script&family=Libre+Baskerville&family=Lobster&family=Lora&family=Merriweather:wght@300&family=Nanum+Myeongjo&family=Nunito:wght@200&family=Oleo+Script+Swash+Caps&family=Open+Sans:wght@500&family=Oswald:wght@300&family=Philosopher&family=Playball&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@700&family=Rubik+Dirt&family=Slabo+27px&family=Source+Serif+Pro:ital,wght@1,600&family=Space+Mono:ital@1&family=Tiro+Kannada&family=Tiro+Tamil&family=Tiro+Telugu&family=Varela+Round&display=swap');

/* ///////////////////////////////////// Section 1 ///////////////////////////////////////////////// */

/* .AboutUs-Section{
    border: 1px solid red;
} */
.AboutUs-banner-Image {
    height: 86vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/Kathmandu.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


/* ///////////////////////////////////// Section 2 ///////////////////////////////////////////////// */


.AboutUs-Section-2 {
    margin-top: 10vh;
}

.About-Section-2-info {
    display: flex;
    width: 100%;
    justify-content: center;
}

.AboutUs-Section-2-title h5 {
    text-align: center;
    font-family: var(---opensansFont);
    font-weight: normal;
    color: #111;
    padding-bottom: 20px;
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;

}

.AboutUs-Section-2-title p {
    font-size: .90rem;
    color: #808080;
    font-weight: 400;
    text-align: center;
    max-width: 700px;
    font-weight: bold;
    font-family: 'Nanum Myeongjo', serif;

}

/* ///////////////////////////////////// Section 3 ///////////////////////////////////////////////// */


.AboutUs-Section-3 {
    margin-top: 10vh;
}

.About-Owner-Image {
    height: 60vh;
    width: 45vh;
    margin: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

@media(max-width:990px) {
    .About-Owner-Image {
        width: 60vh;
        margin-left: 190px;
    }
}

@media(max-width:580px) {
    .About-Owner-Image {
        width: 60vh;
        margin-left: 110px;
    }
}

@media(max-width:430px) {
    .About-Owner-Image {
        width: auto;
        margin-left: 20px;
    }
}

@media(max-width:330px) {
    .About-Owner-Image {
        height: 60vh;
    }
}

.About-Owner-Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Owner-Details {
    margin: 20px;
    padding: 20px;
}

.Owner-Details h4 {
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
}

.Owner-Details p {
    text-align: left;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}


/* ///////////////////////////////////// Section 4 ///////////////////////////////////////////////// */

.AboutUs-Section-4 {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.Company-About-title {
    background-color: #acacac;
    border-radius: 20px;
    margin-bottom: 30px;
    padding-bottom: 1px;
    margin-top: 30px;
}

.Company-Details {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Company-Info {
    height: 60vh;
    width: 100vh;
    background-color: #ededed;
    border-radius: 10px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

@media(max-width:990px) {
    .Company-Details {
        margin: 10px;
    }
}

.Company-Info img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Company-Info p {
    padding: 10px;
    font-size: 13px;
    margin: 0px;
    text-align: left;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}


.Company-Image {
    height: 60vh;
    position: absolute;
    width: 89vh;
}

.SFour-Image {
    margin: 10px;
    height: 45vh;
}

.Company-Image img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}


/* Vision Mission */

.VisionMission {
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OurVision {
    height: 100%;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    padding: 10px;
}

.OurVision h3 {
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
}

.OurVision p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.OurVision:hover {
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
    background-color: #e6e6e6;
}

.OurMission {
    height: 100%;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    padding: 10px;
}

@media(max-width:768px) {
    .OurMission {
        margin-top: 15px;
    }
}

.OurMission h3 {
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
}

.OurMission p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.OurMission:hover {
    background-color: #e6e6e6;
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
}

.hrtags {
    width: 30vh;
}