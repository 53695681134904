html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.navbar{
    background-color: rgb(237 237 237);
    /* position: absolute; */
    width: 100%;
}

.navbar-collapse {
    justify-content: center;
}
.navbar-brand img{
    height: 60px;
    width: 160px;
}

.navbar-nav{
    width: 100%;
    justify-content: end;
}

.navbar-nav li{

}
.navbar-nav li a{
    list-style: none;
    text-decoration: none;
    margin: 5px;
    color: rgb(0 0 111);    
    font-family: 'EB Garamond', serif;
    font-weight: 600;
    font-size: 18px;
}
