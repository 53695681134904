/* ///////////////////////////////////////////////// Destination section-1 //////////////////////////////////////////////////////// */

.Destination-banner-image {
    height: 86vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/Untitled_Panorama1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Destination-banner-image img {
    height: 100%;
    width: 100%;
}




/* ///////////////////////////////// Section 2 /////////////////////////////////// */

.Destination-Section_2 {
    /* border: 1px solid red; */
    margin-top: 5vh;
}

.Destination-Info {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.section-2-picture {
    height: 85px;
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.section-2-picture img {
    height: 100%;
    width: 50%;
}

.Section-2-info {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Section-2-info h3 {
    font-size: 24px;
    margin-top: 25px;
    font-family: 'Libre Baskerville', serif;
    font-weight: bold;
}

.Section-2-info p {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
    text-align: left;
}


/* ////////////////////////////////////////// Destination Card Section 3 ////////////////////////////////////////////////// */


.Destination-Section-3 {
    margin-top: 5vh;
    margin-bottom: 10vh;
}

.title {
    background-color: #acacac;
    border-radius: 20px;
    margin-bottom: 30px;
}

.title h1 {
    color: aliceblue;
    padding-bottom: 5px;
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}

.card-Name h2 {
    color: rgb(205, 205, 205);
    font-size: 15px;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.card-Name p {
    color: rgb(205, 205, 205);
    font-size: 15px;
    padding: 0px;
    margin-bottom: 5px;
}

.Destination-card {
    height: 55vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.Destination-card img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Destination-card:hover{
    transform: scale(1.2);
  }

.Destination-card:hover{
    transform: translateY(-5px);
    transition: transform .25s,-webkit-transform .25s;
}



.Destination-card::before {
    content: " ";
    position: absolute;
}

.Destination-card::after {
    border: 25px solid transparent;
    border-right-color: #acacac;
    transform: rotate(-135deg);
    left: -24.9px;
    top: 250px;
}

.card-facility {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6));
    border-radius: 5px;
}

.Card-M {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Card-M h5 {
    /* border: 1px solid red; */
    padding: 0px;
    margin: 0px;
    margin-left: 15px;
    color: aliceblue;
}

.Card-M svg {
    color: aliceblue;
    font-size: 25px;
}

.Card-T svg {
    color: aliceblue;
    font-size: 22px;
    padding-bottom: 2px;
}

.Card-B svg {
    color: aliceblue;
    font-size: 25px;
}