.Adventures-Section {
    /* border: 1px solid red; */
    height: 86vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: url("../Images/Chitwan Nepal.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


/* ///////////////////////////////////// section 2 //////////////////////////////////////////////// */

.Adventures-Section-2 {
    /* border: 1px solid red; */
}

.Adventures-title {
    background-color: #acacac;
    border-radius: 20px;
    margin-bottom: 30px;
    padding-bottom: 1px;
    margin-top: 30px;
}

.Adventures-title h1 {
    color: aliceblue;
    padding-bottom: 0px;
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
    padding-top: 5px;
}

.Adventure-1 {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AdventureImage-1 {
    /* border: 1px solid rgb(0, 76, 255); */
    height: 55vh;
    width: 90vh;
    margin: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.AdventureImage-1 img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Adventure-info-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Aventure-details-1 {
    width: 90vh;
    margin: 20px;
    padding-top: 30px;
}


.Aventure-details-1 h4 {
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
}

.Aventure-details-1 p {
    text-align: left;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}