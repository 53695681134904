.ReviewsName {
    height: 240px;
    width: 100%;
}

.ReviewsName img {
    height: 100% !important;
    width: 100% !important;
}

.ReviewsDescription {
    margin: 10px;
    width: 100%;
    text-align: left;
}

.Reviews-Container {
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    margin: 12px;
    padding: 20px;
}

.swiper-button-prev {
    top: 150px;
    display: none;
}

.swiper-button-next {
    top: 150px;
    display: none;
}


.Rtitle h4 {
    font-weight: bold;
    color: rgb(80, 80, 80);
    font-family: 'Crimson Text',
        serif;
    font-size: 40px;
    font-weight: 900;
}

.ReviewsName h5 {
    color: #8d8d8d;
    padding: 7px 0;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 30px 0 30px 0;
    /* position: relative; */
    font-family: sans-serif;
    text-align: center;
}

.ReviewsDescription h6{
    color: gold;
    margin-left: 10px;
}

.star{
    display: flex;
}

.ReviewsDescription p {
    text-align: left;
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}