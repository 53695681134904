/* ///////////////////////// section 1 //////////////////////////// */

.ContactUs-Section {
    /* border: 1px solid black; */
}

.ContactUs-banner-Image {
    height: 86vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background:url("../Images/Chitwan.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.ContactUs-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.ContactUs-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

/* ///////////////////////// section 1 //////////////////////////// */


.ContactUs-Section-2-logo {
    margin-bottom: 10vh;
}

.starLogo {
    display: inline-block;
    height: 10vh;
    width: 10vh;
}

.starLogo img {
    height: 100%;
    width: 100%;
}

.ContactUs-Section-2head h2 {
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}


.ContactUs-Section-2head p {
    padding-left: 100px;
    padding-right: 100px;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}


/* ///////////////////////// section 2 //////////////////////////// */

.Contact-Detains-Section2 {}

.Office-1 {

}

.logocircle{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media(max-width:985px){
    .Office-1{

    }
}

.Office-1 svg {
    font-size: 45px;
    color: white;
    
}

.Office-icon-1 {
    display: inline-block;
    width: 20vh;
    height: 20vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgb(51, 49, 59);
    border-radius: 70%;
}

.Office-2 {}

.Office-2 svg {
    font-size: 45px;
    color: white;

}

.Office-icon-2 {
    display: inline-block;
    width: 20vh;
    height: 20vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgb(51, 49, 59);
    border-radius: 70%;

}

.contact-details {}

.contact-details svg {
    font-size: 45px;
    color: white;

}

.ContactUs-call-logo {
    display: inline-block;
    width: 20vh;
    height: 20vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgb(51, 49, 59);
    border-radius: 70%;
}

.mails {}

.mails svg {
    font-size: 45px;
    color: white;

}

.ContactUs-email-logo {
    display: inline-block;
    width: 20vh;
    height: 20vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgb(51, 49, 59);
    border-radius: 70%;
}

.Office-Address-1 {
    margin-top: 12px;
}

.Office-Address-1 h3 {
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}

.Office-Address-1 p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.Office-Address-2 {
    margin-top: 12px;
}

.Office-Address-2 h3 {
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}

.Office-Address-2 p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.Phone-no {
    margin-top: 12px;
    font-size: 20px;
}

.Phone-no h3 {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
    font-size: 20px;
}


.Phone-no h4 {
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}

.Email-Email {
    margin-top: 12px;
}

.Email-Email h3 {
    font-family: 'Crimson Text', serif;
    font-size: 35px;
    font-weight: bold;
}

.Email-Email h4 {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

/* //////////////////////////////////////////// section 4 /////////////////////////////////////////// */

.ContactUs-form-Section-4 {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.contact-form-image {
    height: 60vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.form-control {
    background-color: rgb(228, 228, 228);
    height: 50px;
}

.Email {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Phone-no {
    margin-top: 13px;
    margin-bottom: 20px;
}

.message textarea {
    height: 100px;
}

.form-submit-button{
    border: none;
    margin-top: 20px;
    width: 200px;
    height: 35px;
    border-radius: 5px;
}