.Home-banner-Image {
    /* border: 1px solid red; */
    border: none;
    height: 86vh;
    /* background: url("../Images/Home-Banner_image.webp"); */
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
}

.Home-Banner-text {
    color: #34383b;
    padding-top: 100px;
}


.HomeBannerSwiper1 {
    height: 86vh !important;
    background: url("../Images/BannerImage01.JPG");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.HomeBannerSwiper2 {
    height: 86vh !important;
    background: url("../Images/Chitwan\ Nepal.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.HomeBannerSwiper3 {
    height: 86vh !important;
    background: url("../Images/Nagarkot.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}




/* //////////////////////////////////////////// SECTION 2 /////////////////////////////////////////// */

.Home-Section-2 {
    margin-top: 20vh;
}

.top-logo {
    height: 50px;
    width: 50px;
}

/* ======================================== Category Section ======================== */

.destinationSection {
    background-color: #eee;
}

.destinationSection .titleSection img {
    height: 66px;
}

.titleSection h2 {
    /* font-family: 'Crimson Text', serif; */
    font-family: amithenregular;
    font-size: 35px;
    font-weight: bold;
}
.titleSection h1 {
    /* font-family: 'Crimson Text', serif; */
    font-family: amithenregular;
    font-size: 35px;
    font-weight: bold;
}

.titleSection p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.titleSection img {
    height: 60px;
    width: 60px;
}

.categryBanner {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: start;   */
    align-items: flex-end;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mid-image {
    width: 95%;
    height: 95%;
}

.categryBanner h3 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    font-family: var(---robotFont);
}

.categryBanner h3::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2px;
    background: #fff;
    right: 0;
    bottom: 0;
}



/* //////////////////////////////////////////// SECTION 3 /////////////////////////////////////////// */

.aboutSection {
    margin-top: 10vh;
    margin-bottom: 10vh;

}

.Homepage-Company_Image {
    margin: 15px;
}

.C-Images{
    margin: 2px;
    height: 30vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.C-Images img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.CImage {
    height: 25vh;
}

.CImage img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 5px gray;
}


/* .Homepage-Company_Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
} */

.aboutImg {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 350px;
}

.Award-Image {
    /* border: 1px solid black; */
    height: 30vh;
    margin: 2px;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.Award-Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.aboutText {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px;
}

.AboutCompany {
    margin-top: 12px;
}

.aboutText h3 {
    font-size: 1.6rem;
    color: #111;
    font-weight: 500;
    text-align: justify;
}

.aboutText p {
    font-size: .90rem;
    text-align: justify;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;

}

.aboutButton button {
    background-color: var(---primeColor);
    color: #111;
    font-weight: 500;
    text-transform: capitalize;
    background-color: rgb(2 73 255 / 76%);
}

.aboutButton {
    float: left;
    padding: 5px;
}

@media(max-width:500px) {
    .aboutText h3 {
        font-size: 1.2rem;
        color: #111;
        font-weight: 500;
        text-align: justify;
        font-family: var(---robotFont);
    }
}

@media(max-width:768px){
    .C-Images{
        width: 50vh;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Homepage-Company_Image{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.Award {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.Award-row {
    margin-top: 5vh;
}

.Award-Images {
    margin: 5px;
    height: 21vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    width: 90%;
}

@media(max-width:550px) {
    .Award-Images {
        width: 45vh;
    }

}

.Award-Images img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

/* //////////////////////////////////////////// SECTION 3 /////////////////////////////////////////// */

.enquiryBanner {
    /* background: url("../Images/NEPAL\ TOURISM.png"),rgb(208, 208, 208); */
    background-size: cover;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.enquiryText {
    height: 90vh;
    /* border: 1px solid red; */
}

.enquiryText img {
    width: 100%;
    height: 100%;
}





/* ==================================== Number Of Clients ========================= */
.numberClients {
    background-color: #eee;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.cardHover {
    width: 200px;
    height: 200px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    perspective: 1000px;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
}

.cardHover:hover {
    transition: all 0.3s linear;
    transform: scale(1.1);
}

.clientCard {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #111;
    transition: all 0.6s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    transform: rotateX(0deg) rotateY(0deg);
    perspective: 1000px;
    box-shadow: 0 3px 7px #808080;
    transform-style: preserve-3d;
}

.cardHover:hover .clientCard {
    box-shadow: 0 8px 15px #808080;
}

.card-content {
    transform: translateZ(40px);
}

.card-content h2 {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background: radial-gradient(circle at 100px 100px, rgb(254, 186, 1, .7), rgb(254, 186, 1));
    font-size: 1.2rem;
    margin: auto;
    position: relative;
    box-shadow: 0 4px 5px #aaa, inset 2px 2px 20px 3px rgb(254, 186, 1, .2);
}

.card-content p {
    color: #111;
    margin: 10px 0;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: var(---robotFont);
}

.t_over {
    width: 33.333%;
    height: 33.333%;
    position: absolute;
    z-index: 1;
}

.t_over:nth-child(1) {
    left: 0;
    top: 0;
}

.t_over:nth-child(2) {
    left: 33.333%;
    top: 0;
}

.t_over:nth-child(3) {
    left: 66.666%;
    top: 0;
}

.t_over:nth-child(4) {
    left: 0;
    top: 33.333%;
}

.t_over:nth-child(5) {
    left: 33.333%;
    top: 33.333%;
}

.t_over:nth-child(6) {
    left: 66.666%;
    top: 33.333%;
}

.t_over:nth-child(7) {
    left: 0;
    top: 66.666%;
}

.t_over:nth-child(8) {
    left: 33.333%;
    top: 66.666%;
}

.t_over:nth-child(9) {
    left: 66.666%;
    top: 66.666%;
}

.t_over:nth-child(1):hover~.clientCard {
    transform: rotateX(-20deg) rotateY(20deg)
}

.t_over:nth-child(2):hover~.clientCard {
    transform: rotateX(-20deg) rotateY(0deg)
}

.t_over:nth-child(3):hover~.clientCard {
    transform: rotateX(-20deg) rotateY(-20deg)
}

.t_over:nth-child(4):hover~.clientCard {
    transform: rotateX(0deg) rotateY(20deg)
}

.t_over:nth-child(5):hover~.clientCard {
    transform: rotateX(0deg) rotateY(0deg)
}

.t_over:nth-child(6):hover~.clientCard {
    transform: rotateX(0deg) rotateY(-20deg)
}

.t_over:nth-child(7):hover~.clientCard {
    transform: rotateX(20deg) rotateY(20deg)
}

.t_over:nth-child(8):hover~.clientCard {
    transform: rotateX(20deg) rotateY(0deg)
}

.t_over:nth-child(9):hover~.clientCard {
    transform: rotateX(20deg) rotateY(-20deg)
}

@media(max-width:990px) {
    .card-content p {
        font-size: 1rem;
    }
}


/* ================================================= Gallery =========================== */
#gallery {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.img-wrapper {
    position: relative;
    width: 100%;
    height: 50vh;
    max-height: 400px;
    cursor: pointer;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    overflow: hidden;
}

.img-wrapper:hover {
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
}


.img-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.img-wrapper:hover img {
    transform: scale(1.1);
}

.img-overlay {
    position: absolute;
    width: 100%;
    height: 7vh;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6));
    border-radius: 5px;
    color: rgb(218, 218, 218);
}

.img-overlay h4 {
    color: rgb(255 255 255 / 91%);
    font-family: 'Crimson Text', serif;
    font-size: 25px;
    font-weight: 100;
    margin: 5px;
}

@media screen and (min-width: 768px) {
    #overlay img {
        width: 60%;
    }
}

@media screen and (min-width:1200px) {
    #overlay img {
        width: 50%;
    }
}

#nextButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
}

#nextButton:hover {
    opacity: 0.7;
}

@media screen and (min-width:768px) {
    #nextButton {
        color: #fff;
        font-size: 3em;
        transition: opacity 0.8s;
    }
}

#prevButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
}

#prevButton:hover {
    opacity: 0.7;
}

@media screen and (min-width:768px) {
    #prevButton {
        color: #fff;
        font-size: 3em;
        transition: opacity 0.8s;
    }
}

#exitButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    position: absolute;
    top: 15px;
    right: 15px;
}

#exitButton:hover {
    opacity: 0.7;
}

@media screen and (min-width:768px) {
    #exitButton {
        color: #fff;
        font-size: em;
        transition: opacity 0.8s;
        position: absolute;
        top: 15px;
        right: 15px;
    }
}


/* ======================================= Folow Section ==================== */

.ourSocialLink {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .5)), url("../Images/sunrise-view.jpg");
    background-size: cover;
    background-position: center center;
    padding: 70px 20px;
}




.socialText h2 {
    color: #f4f4f4;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Crimson Text', serif;
    font-size: 50px;
    letter-spacing: 3px;
    text-align: left;
}

.socialText p {
    color: #fff;
    font-weight: normal;
    font-size: 1.4rem;
    font-family: var(---robotFont);
}

.socialItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
}

.socialItems li {
    margin: 0 10px;
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #fff;
}

.socialItems li:hover {
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    border: 2px solid #fff;
}

.socialItems li a {
    text-decoration: none;
    color: #111;
    font-size: 1.4rem;
    cursor: pointer;
}

.socialItems li a svg {
    font-size: 30px;
}

.socialItems li:hover a {
    color: #fff;
}


@media(max-width:770px) {
    .socialText {
        text-align: center;
        width: 100%;
    }

    .socialText h2 {
        text-align: center;
    }

    .socialItems {
        text-align: center;
        width: 100%;
    }

}

/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */


.Hotel-Section {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.Hotel-List h3 {
    margin: 20px;
    font-weight: bold;
    color: rgb(80, 80, 80);
    font-family: 'Crimson Text', serif;
    font-size: 40px;
    font-weight: 900;
}

.swiper-slide img {
    height: 60vh !important;
    width: 60vh !important;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}







/* /////////////////////////////////////////////// .cutomerReview//////////////////////////////////////////////// */

.cutomerReview {
    /* background-image: Url('../Img/home-page-back.jpg'); */
    overflow: hidden;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 20px 100px;
    justify-content: center;
    align-items: center;
}

.cutomerReview .cutomerReview .carousel-inner,
.cutomerReview .carousel-item {
    width: 100%;
    height: auto;
}

.cutomerReview h2 {
    margin-bottom: 50px;
    font-weight: bold;
    color: rgb(80, 80, 80);
    font-family: 'Crimson Text', serif;
    font-size: 40px;
    font-weight: 900;
}

.testimonialText p {
    text-align: center;
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;

}

.testimonialName h2 {
    color: #8d8d8d;
    padding: 7px 0;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 30px 0 7px 0;
    position: relative;
    font-family: sans-serif;
    text-align: center;
}

.testimonialName h5 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    font-family: serif;
    text-align: center;
}

.testimonialName h2:before {
    content: "";
    position: absolute;
    width: 4rem;
    height: auto;
    background: var(---primeColor);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.cutomerReview .carousel-indicators [data-bs-target] {
    width: 0.6rem;
    height: auto;
    background-color: var(---primeColor);
    border: 2px solid var(---primeColor);
    margin: 0 5px;
    border-radius: 100%;
}

.cutomerReview .carousel-control .material-symbols-outlined {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    color: var(---secondColor);
    font-size: 3rem;
    color: black;
}

.cutomerReview .carousel-control i:hover {
    opacity: 1;
}

.rightIcon {
    transform: rotateY(180deg);
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 16%;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

@media(max-width:990px) {
    .cutomerReview {
        overflow: hidden;
        padding: 0px 14px;
        justify-content: center;
        align-items: center;
    }

    .cutomerReview .carousel-inner,
    .cutomerReview .carousel-item {
        width: 100%;
        height: auto;
    }

    .cutomerReview h2 {
        margin-bottom: 0px;
    }

    .testimonianlBody {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .testimonialText p {
        text-align: center;
        font-size: .80rem;
    }

    .testimonialName h2 {
        padding: 7px 0;
        font-size: 1.1rem;
        font-weight: 500;
        border-radius: 5px;
        margin: 30px 0 7px 0;
        position: relative;
        text-align: center;
    }

    .testimonialName h5 {
        font-size: 1rem;
        font-weight: 500;
        position: relative;
        text-align: center;
    }

    .testimonialName h2:before {
        content: "";
        position: absolute;
        width: 4rem;
        height: auto;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .cutomerReview .carousel-indicators [data-bs-target] {
        width: 0.6rem;
        height: auto;
        margin: 0 5px;
        border-radius: 100%;
    }

    .cutomerReview .carousel-control img {
        width: 30px;
        height: au;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
    }

    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        padding: 0;
        text-align: center;
        background: 0 0;
        border: 0;
        opacity: .5;
        transition: opacity .15s ease;
    }
}



@media(max-width:500px) {
    .cutomerReview .titleSection h2 {
        font-size: 1.4rem;
    }

    .cutomerReview .carousel-inner,
    .cutomerReview .carousel-item {
        width: 100%;
        height: auto;

    }
}


@media(max-width:1000px) {
    .cutomerReview .carousel-control .material-symbols-outlined {
        display: none;

    }
}



/* //////////////////////////////////////////////////////// last section //////////////////////////////////////////////////// */


.Extra-1 {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .5)), url("../Images/nepal airlines aircraft1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Extra-section {}

.ExtraImages {
    height: 15vh;
}

.ExtraImages img {
    height: 100%;
    width: 100%;
}

.Extra-quotes h3 {
    color: #ffffffd1;
    padding-top: 20px;
    font-family: 'Crimson Text', serif;
    font-size: 30px;
    font-weight: bold;
}


.ExtraImages-Details h3 {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: rgb(229, 229, 229);
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 18px;
}

.ExtraImages-Details p {
    font-size: 14px;
    color: rgb(229, 229, 229);
    line-height: 1.71;
}

.Copyright {
    margin-top: 25px;
}


.Copyright h3 {
    color: #b3b3b3;
    font-size: 1rem;
}

.skdm {
    margin-top: 25px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.skdm h3 {
    color: #b3b3b3;
    font-size: 1rem;
}

.skdm a {
    margin-bottom: 10px;
    margin-left: 5px;
}




/* //////////////////////////////////////////// Hotel Gallery Section /////////////////////////////////////////////// */

.Hotel-image-wrapper {
    height: 50vh;
    position: relative;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
}

.Hotel-image-wrapper:hover {
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
}

.Hotel-image-wrapper:hover img {
    transform: scale(1.1);
}

.Hotel-image-wrapper img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.Hotel-img-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6));
    border-radius: 5px;
    color: rgb(218, 218, 218);
    border-radius: 5px;

}

.Hotel-img-overlay h3 {
    color: rgb(255 255 255 / 91%);
    font-family: 'Crimson Text', serif;
    font-size: 25px;
    font-weight: 100;
    margin: 5px;
}



/* ///////////////////////////////////////////// testimonial //////////////////////////////////////// */

.testimonial{
    border: 1px solid red;
    margin-top: 10vh;
}